<template>
  <b-form-group :label="label" label-for="input" :label-class="{ 'required-field': required }">
    <b-overlay :show="loading" rounded="sm">
      <b-form-input
        :disabled="disabled"
        :type="type"
        :placeholder="placeholder"
        :readonly="readonly"
        v-model="name"
        autocomplete="off"
        @input="validate ? v.$touch() : null"
        @focus="focus ? $event.target.select() : ''"
        :class="{ 'search-input': type === 'search' }"
        :step="step"
        v-tooltip="{
          content: error,
          show: validate && v.$error,
          trigger: 'manual',
          placement: 'bottom',
          classes: ['danger', tooltipClass]
        }"
      />
      <template #overlay>
        <pulse-loader :loading="loading" :color="fill" size="10px"></pulse-loader>
      </template>
    </b-overlay>
  </b-form-group>
</template>

<script>
import PulseLoader from 'vue-spinner/src/PulseLoader'
import variables from '@/assets/css/sass/themes/_perform.sim.scss'
export default {
  components: {
    PulseLoader
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    error: {
      type: String,
      default: ''
    },
    focus: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    },
    loading: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: 'Your input'
    },
    readonly: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    step: {
      type: [String, Number],
      default: 0.1
    },
    tooltipClass: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    v: {
      type: Object,
      required: false
    },
    validate: {
      type: Boolean,
      default: false
    },
    value: {
      type: [String, Number],
      default: ''
    }
  },
  data() {
    return {
      fill: variables.primaryColor
    }
  },
  computed: {
    name: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.search-input {
  font-size: 0.9rem;
  padding-right: 0.5rem;
  background-image: linear-gradient(rgba(#fff, 0.8), rgba(#fff, 0.8)),
    url('/assets/img/svg/search-fill.svg');
  background-repeat: no-repeat;
  background-position: right 0.5rem center;
  background-size: 1rem;
}
</style>
