<template>
  <div :class="{ 'policy-tip-page': true, shown: isOpen }" @mouseenter="mouseEnter">
    <b-row class="mx-0 py-4">
      <b-col v-for="(content, index) in policiesPage" :key="`content-modal-${index}`">
        <div class="text-center content-icon" :class="{ active: selectedContentIndex === index }">
          <div
            :key="`content-img-${index}`"
            class="content-img-bg mx-auto"
            @click="onContentClick(index)"
          >
            <img :src="content.img" alt="" />
          </div>
          <p class="my-2 content-title">{{ content.title }}</p>
        </div>
      </b-col>

      <transition name="fade" mode="out-in">
        <div class="p-4 policy-content" :key="`content-body-${selectedContentIndex}`">
          <div class="text-center mb-3">
            <blockquote class="m-0">
              <p class="policy-subtitle mb-2">
                {{ policiesPage[selectedContentIndex].subtitle }}
              </p>
            </blockquote>
          </div>
          <div class="d-flex policy-detail mb-2">
            <i class="iconsminds-light-bulb-2 align-self-center mr-2"></i>
            <p class="d-inline mb-0 align-self-center">{{ $t('pages.policies.overview-title') }}</p>
          </div>
          <p v-html="policiesPage[selectedContentIndex].overview"></p>
          <div class="d-flex policy-detail mb-2">
            <i class="iconsminds-information align-self-center mr-2"></i>
            <p class="d-inline mb-0 align-self-center">{{ $t('pages.policies.covered-title') }}</p>
          </div>
          <div
            v-if="locale"
            v-html="formatContent(policiesPage[selectedContentIndex].covered)"
          ></div>
          <div v-else v-html="policiesPage[selectedContentIndex].covered"></div>
        </div>
      </transition>
    </b-row>
    <a href="#" class="d-flex policy-tip-page-button" @click.prevent="toggle">
      <i class="align-self-center fas" :class="`fa-chevron-${isOpen ? 'right' : 'left'}`" />
      <img src="/assets/img/svg/claims/policy.svg" alt="policy" class="align-self-center ml-2" />
    </a>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { policiesPage } from '@/data/pageContents'
import { formatBasePageContent } from '@/constants/config'
export default {
  props: {
    menuOver: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isOpen: false,
      isMenuOver: false,
      selectedColor: '',
      policiesPage,
      selectedContentIndex: 0
    }
  },
  methods: {
    formatContent(content) {
      return formatBasePageContent(content)
    },
    onContentClick(index) {
      let self = this
      self.selectedContentIndex = index
      self.$emit('selectedIndex', index)
    },
    addEvents() {
      document.addEventListener('click', this.handleDocumentClick)
      document.addEventListener('touchstart', this.handleDocumentClick)
    },
    removeEvents() {
      document.removeEventListener('click', this.handleDocumentClick)
      document.removeEventListener('touchstart', this.handleDocumentClick)
    },
    handleDocumentClick(e) {
      if (!this.isMenuOver) {
        this.toggle()
      }
    },
    toggle() {
      this.isOpen = !this.isOpen
      if (!this.isOpen) {
        this.$emit('toggleClosed')
      }
    },
    mouseEnter() {
      this.isMenuOver = true
    },
    mouseLeave() {
      this.isMenuOver = false
    }
  },
  computed: {
    ...mapGetters(['locale']),
    outMenuOver: {
      get() {
        return this.menuOver
      }
    }
  },
  watch: {
    isOpen(val) {
      if (val) {
        this.addEvents()
      } else {
        this.removeEvents()
      }
    },
    outMenuOver(val) {
      // this.isMenuOver = !this.isMenuOver
      // this.isOpen = !this.isOpen
      if (val) {
        this.mouseEnter()
        this.toggle()
      } else {
        this.mouseLeave()
        this.toggle()
      }
    }
  },
  beforeDestroy() {
    this.removeEvents()
  }
}
</script>
